export const footerInstagramBlack = (e) => {
  e.stopPropagation();
  localStorage.setItem("footer-instagram-color", "black");
  document.getElementById("instagram-footer").src = process.env.PUBLIC_URL + "/images/instagram_black.png";
};

export const footerInstagramWhite = (e) => {
  e.stopPropagation();
  localStorage.setItem("footer-instagram-color", "white");
  document.getElementById("instagram-footer").src = process.env.PUBLIC_URL + "/images/instagram_white.png";
};

export const recallFooterInstagramColor = () => {
  const color = localStorage.getItem('footer-instagram-color');
  if (color !== null) {
    if (color === "white") {
      return process.env.PUBLIC_URL + "/images/instagram_white.png";
    } else if (color === "black") {
      return process.env.PUBLIC_URL + "/images/instagram_black.png";
    }
  } else {
    return process.env.PUBLIC_URL + "/images/instagram_black.png";
  }
};

export const footerLinkedinBlack = (e) => {
  e.stopPropagation();
  localStorage.setItem("footer-linkedin-color", "black");
  document.getElementById("linkedin-footer").src = process.env.PUBLIC_URL + "/images/linkedin_black.png";
};

export const footerLinkedinWhite = (e) => {
  e.stopPropagation();
  localStorage.setItem("footer-linkedin-color", "white");
  document.getElementById("linkedin-footer").src = process.env.PUBLIC_URL + "/images/linkedin_white.png";
};

export const recallFooterLinkedinColor = () => {
  const color = localStorage.getItem('footer-linkedin-color');
  if (color !== null) {
    if (color === "white") {
      return process.env.PUBLIC_URL + "/images/linkedin_white.png";
    } else if (color === "black") {
      return process.env.PUBLIC_URL + "/images/linkedin_black.png";
    }
  } else {
    return process.env.PUBLIC_URL + "/images/linkedin_black.png";
  }
};

export const settingsArrowBlack = (e) => {
  e.stopPropagation();
  localStorage.setItem("settings-arrow-color", "black");
  document.getElementById("settings-arrow-theme").src = process.env.PUBLIC_URL + "/images/arrow_black.png";
  document.getElementById("settings-arrow-text").src = process.env.PUBLIC_URL + "/images/arrow_black.png";
};

export const settingsArrowWhite = (e) => {
  e.stopPropagation();
  localStorage.setItem("settings-arrow-color", "white");
  document.getElementById("settings-arrow-theme").src = process.env.PUBLIC_URL + "/images/arrow_white.png";
  document.getElementById("settings-arrow-text").src = process.env.PUBLIC_URL + "/images/arrow_white.png";
};

export const recallSettingsArrowColor = () => {
  const color = localStorage.getItem('settings-arrow-color');
  if (color !== null) {
    if (color === "white") {
      return process.env.PUBLIC_URL + "/images/arrow_white.png";
    } else if (color === "black") {
      return process.env.PUBLIC_URL + "/images/arrow_black.png";
    }
  } else {
    return process.env.PUBLIC_URL + "/images/arrow_black.png";
  }
};