export const lineBlack = (e) => {
  e.stopPropagation();
  localStorage.setItem("line-color", "black");
  document.body.style.setProperty("--line-color", "black");
};

export const lineWhite = (e) => {
  e.stopPropagation();
  localStorage.setItem("line-color", "white");
  document.body.style.setProperty("--line-color", "white");
};

export const recallLineColor = () => {
  const color = localStorage.getItem('line-color');
  if (color !== null) {
    document.body.style.setProperty("--line-color", color);
  }
};