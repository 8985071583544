import React from "react";
import Nav from "components/navigation/navigation"
import Footer from "components/footer/footer"
import "./resources/main.css"
import  { Routes, Route } from "react-router-dom";
import Home from "components/pages/home"
import Contact from "components/pages/contact"
import Settings from "components/pages/settings"

const Main = () => {
    return (
        <div>
            <Nav></Nav>
            <div className="content">
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/settings" element={<Settings/>}/>
                </Routes>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Main