import { textBlack, textWhite, recallTextColor } from "services/changeText"
import { yellowBackground, blueBackground, redBackground, greenBackground, darkBackground, recallBackground } from "services/changeBackground"
import { lineBlack, lineWhite, recallLineColor } from "services/changeLayoutBarriers"
import { footerInstagramBlack, footerInstagramWhite, footerLinkedinBlack, footerLinkedinWhite, settingsArrowBlack, settingsArrowWhite } from "./updateImage";

export const yellowTheme = (e) => {
  e.stopPropagation();
  yellowBackground(e);
  lineBlack(e);
  textBlack(e);
  footerInstagramBlack(e);
  footerLinkedinBlack(e);
  settingsArrowBlack(e);
};

export const greenTheme = (e) => {
  e.stopPropagation();
  greenBackground(e);
  lineBlack(e);
  textBlack(e);
  footerInstagramBlack(e);
  footerLinkedinBlack(e);
  settingsArrowBlack(e);
};

export const blueTheme = (e) => {
  e.stopPropagation();
  blueBackground(e);
  lineBlack(e);
  textBlack(e);
  footerInstagramBlack(e);
  footerLinkedinBlack(e);
  settingsArrowBlack(e);
};

export const redTheme = (e) => {
  e.stopPropagation();
  redBackground(e);
  lineBlack(e);
  textBlack(e);
  footerInstagramBlack(e);
  footerLinkedinBlack(e);
  settingsArrowBlack(e);
};

export const darkTheme = (e) => {
  e.stopPropagation();
  darkBackground(e);
  lineWhite(e);
  textWhite(e);
  footerInstagramWhite(e);
  footerLinkedinWhite(e);
  settingsArrowWhite(e);
};

export const recallTheme = () => {
  recallBackground();
  recallTextColor();
  recallLineColor();
};