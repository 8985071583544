export const textXSmall = (e) => {
  e.stopPropagation();
  localStorage.setItem("font-size", "x-small");
  document.body.style.setProperty("font-size", "x-small");
};

export const textSmall = (e) => {
  e.stopPropagation();
  localStorage.setItem("font-size", "small");
  document.body.style.setProperty("font-size", "small");
};

export const textMedium = (e) => {
  e.stopPropagation();
  localStorage.setItem("font-size", "medium");
  document.body.style.setProperty("font-size", "medium");
};

export const textLarge = (e) => {
  e.stopPropagation();
  localStorage.setItem("font-size", "large");
  document.body.style.setProperty("font-size", "large");
};

export const textXLarge = (e) => {
  e.stopPropagation();
  localStorage.setItem("font-size", "x-large");
  document.body.style.setProperty("font-size", "x-large");
};

export const textBlack = (e) => {
  e.stopPropagation();
  localStorage.setItem("color", "black");
  document.body.style.setProperty("color", "black");
};

export const textWhite = (e) => {
  e.stopPropagation();
  localStorage.setItem("color", "white");
  document.body.style.setProperty("color", "white");
};

export const recallTextSize = () => {
  const size = localStorage.getItem('font-size');
  if (size !== null) {
    document.body.style.setProperty("font-size", size);
  }
};

export const recallTextColor = () => {
  const color = localStorage.getItem('color');
  if (color !== null) {
    document.body.style.setProperty("color", color);
  }
};