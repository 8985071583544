import React from "react";
import "./resources/navigation.css";
import { NavLink } from "react-router-dom";

const Nav = () => {
    return (
        <div className="nav">
            <ul>
                <li><NavLink exact to="/"><b>Home</b></NavLink></li>
                <li><NavLink to="/contact"><b>Contact</b></NavLink></li>
                <li><NavLink to="/settings"><b>Settings</b></NavLink></li>
            </ul>
        </div>
    )
}
export default Nav