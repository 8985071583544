import React, { useState } from 'react';
import { recallSettingsStatus, toggleSettingsStatus } from 'services/editSettings';
import { yellowTheme, blueTheme, redTheme, greenTheme, darkTheme } from "services/changeTheme"
import { recallSettingsArrowColor } from "services/updateImage"
import "./resources/settings-components.css"

function Theme() {
  const [isOpen, setIsOpen] = useState(recallSettingsStatus("theme-settings-open"));
  var dropArrow = recallSettingsArrowColor();

  return (
    <div className="settings">
      <button id="theme-button" className="settings-button" onClick={() => setIsOpen(!isOpen) & toggleSettingsStatus("theme-settings-open")}>
        <p className="left"><b>Theme Selection</b></p>
        <p className="right"><img id="settings-arrow-theme" src={dropArrow} alt="Drop Down Arrow" width="30" height="30"/></p>
      </button>
      {isOpen && (
        <div className="settings-wrapper" onClick={() => setIsOpen(!isOpen) & toggleSettingsStatus("theme-settings-open")}>
          <p><b>Select website theme:</b></p>
          <button id="yellow-theme" onClick={yellowTheme}>Yellow</button>
          <button id="red-theme" onClick={redTheme}>Red</button>
          <button id="blue-theme" onClick={blueTheme}>Blue</button>
          <button id="green-theme" onClick={greenTheme}>Green</button>
          <button id="dark-theme" onClick={darkTheme}>Dark</button>
        </div>
      )}
    </div>
  );
}

export default Theme;