import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "services/initFirebase";
import { validateContact } from "services/validateContact"

// Function to get form value
function getInputVal(id){
  return document.getElementById(id).value;
}

// Save message to firebase
async function saveMessage(name, email, phone, message, time){
  const docRef = await addDoc(collection(db, "contact"),
  {
    name: name,
    email: email,
    phone: phone,
    message: message,
    time: time
  });
  console.log("Document written with ID: ", docRef.id);
}

// Submit form
export const submitContact = (e) => {
  e.preventDefault();

  if (validateContact(e)) {
    //Get value
    var name = getInputVal('name');
    var email = getInputVal('email');
    var phone = getInputVal('phone');
    var message = getInputVal('message');
    var time = Timestamp.now().toDate();

    // Save message
    saveMessage(name, email, phone, message, time);

    // Show alert
    document.querySelector('.alert').style.display = 'block';

    // Hide alert after 10 seconds
    setTimeout(function(){
      document.querySelector('.alert').style.display = 'none';
    },10000);

    // Clear form
    document.getElementById('contactForm').reset();
  }
};