import React from "react";
import "./resources/footer.css";
import { recallFooterInstagramColor, recallFooterLinkedinColor } from "services/updateImage";

const Footer = () => {
    var instagram = recallFooterInstagramColor();
    var linkedin = recallFooterLinkedinColor();
    return (
        <div className="footer">
            <ul>
                <li><a href="https://www.instagram.com/richarddanylo/"><img id="instagram-footer" src={instagram} alt="Instagram Logo With Link"/></a></li>
                <li><a href="https://www.linkedin.com/in/richard-danylo-9a8509289/"><img id="linkedin-footer" src={linkedin} alt="LinkedIn Logo With Link"/></a></li>
            </ul>
        </div>
    )
}
export default Footer