import React, { useState } from 'react';
import { recallSettingsStatus, toggleSettingsStatus } from 'services/editSettings';
import { xsmallSize, mediumSize, smallSize, largeSize} from "services/changeSize"
import { recallSettingsArrowColor } from "services/updateImage"
import "./resources/settings-components.css"

function Text() {
  const [isOpen, setIsOpen] = useState(recallSettingsStatus("text-settings-open"));
  var dropArrow = recallSettingsArrowColor();

  return (
    <div className="settings">
      <button id="text-button" className="settings-button" onClick={() => setIsOpen(!isOpen) & toggleSettingsStatus("text-settings-open")}>
        <p className="left"><b>Text Settings</b></p>
        <p className="right"><img id="settings-arrow-text" src={dropArrow} alt="Drop Down Arrow" width="30" height="30"/></p>
      </button>
      {isOpen && (
        <div className="settings-wrapper" onClick={() => setIsOpen(!isOpen) & toggleSettingsStatus("text-settings-open")}>
          <div>
            <p><b>Select text size:</b></p>
            <button id="x-small-text" onClick={xsmallSize}>X-Small</button>
            <button id="small-text" onClick={smallSize}>Small</button>
            <button id="medium-text" onClick={mediumSize}>Medium</button>
            <button id="large-text" onClick={largeSize}>Large</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Text;