export const recallSettingsStatus = (item) => {
  if (localStorage.getItem(item) !== null) {
    if (localStorage.getItem(item) === "true") {
      return true;
    }
  }
  return false;
};

export const toggleSettingsStatus = (item) => {
  if (localStorage.getItem(item) !== null) {
    if (localStorage.getItem(item) === "true") {
      localStorage.setItem(item,"false");
    } else {
      localStorage.setItem(item,"true");
    }
  } else {
    localStorage.setItem(item,"true");
  }
};