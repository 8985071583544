import { textXSmall, textSmall, textMedium, textLarge, textXLarge, recallTextSize } from "services/changeText"

export const xsmallSize = (e) => {
  e.stopPropagation();
  textXSmall(e);
};

export const smallSize = (e) => {
  e.stopPropagation();
  textSmall(e);
};

export const mediumSize = (e) => {
  e.stopPropagation();
  textMedium(e);
};

export const largeSize = (e) => {
  e.stopPropagation();
  textLarge(e);
};

export const xlargeSize = (e) => {
  e.stopPropagation();
  textXLarge(e);
};

export const recallSize = () => {
  recallTextSize();
};