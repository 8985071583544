import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
    apiKey: "AIzaSyDJUXP5JJMbczc_3YbRHKsYI8UfYZvRvrA",
    authDomain: "richarddanylo-e3f22.firebaseapp.com",
    projectId: "richarddanylo-e3f22",
    storageBucket: "richarddanylo-e3f22.appspot.com",
    messagingSenderId: "296863928177",
    appId: "1:296863928177:web:45c9f5b93b320bb9fbb0d0",
    measurementId: "G-49FCY2KJVH"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);