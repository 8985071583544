import React from "react";
import Theme from "components/settings/theme"
import Text from "components/settings/text"

function Settings() {
    return (
        <div>
            <h1>Settings</h1>
            <div className="settings-out"><Theme/></div>
            <div className="settings-out"><Text/></div>
        </div>
    )
}

export default Settings;