
export const yellowBackground = (e) => {
  e.stopPropagation();
  localStorage.setItem("hue","50");
  document.body.style.setProperty("--hue","50");
  localStorage.setItem("saturation","80%");
  document.body.style.setProperty("--saturation","80%");
  localStorage.setItem("lightness","80%");
  document.body.style.setProperty("--lightness","80%");
  document.body.style.backgroundColor = "hsl(var(--hue), var(--saturation), var(--lightness))";
};

export const blueBackground = (e) => {
  e.stopPropagation();
  localStorage.setItem("hue","184");
  document.body.style.setProperty("--hue","184");
  localStorage.setItem("saturation","100%");
  document.body.style.setProperty("--saturation","100%");
  localStorage.setItem("lightness","86%");
  document.body.style.setProperty("--lightness","86%");
  document.body.style.backgroundColor = "hsl(var(--hue), var(--saturation), var(--lightness))";
};

export const redBackground = (e) => {
  e.stopPropagation();
  localStorage.setItem("hue","360");
  document.body.style.setProperty("--hue","360");
  localStorage.setItem("saturation","90%");
  document.body.style.setProperty("--saturation","90%");
  localStorage.setItem("lightness","64%");
  document.body.style.setProperty("--lightness","64%");
  document.body.style.backgroundColor = "hsl(var(--hue), var(--saturation), var(--lightness))";
};

export const greenBackground = (e) => {
  e.stopPropagation();
  localStorage.setItem("hue","88");
  document.body.style.setProperty("--hue","88");
  localStorage.setItem("saturation","77%");
  document.body.style.setProperty("--saturation","77%");
  localStorage.setItem("lightness","78%");
  document.body.style.setProperty("--lightness","78%");
  document.body.style.backgroundColor = "hsl(var(--hue), var(--saturation), var(--lightness))";
};

export const darkBackground = (e) => {
  e.stopPropagation();
  localStorage.setItem("hue","203");
  document.body.style.setProperty("--hue","203");
  localStorage.setItem("saturation","2%");
  document.body.style.setProperty("--saturation","2%");
  localStorage.setItem("lightness","10%");
  document.body.style.setProperty("--lightness","10%");
  document.body.style.backgroundColor = "hsl(var(--hue), var(--saturation), var(--lightness))";
};

export const recallBackground = () => {
  const hue = localStorage.getItem('hue');
  const saturation = localStorage.getItem('saturation');
  const lightness = localStorage.getItem('lightness');
  if (hue !== null & saturation !== null & lightness !== null) {
    document.body.style.setProperty("--hue",hue);
    document.body.style.setProperty("--saturation",saturation);
    document.body.style.setProperty("--lightness",lightness);
    document.body.style.backgroundColor = "hsl(var(--hue), var(--saturation), var(--lightness))";
  }
};