// Function to get form value
function getInputVal(id){
  return document.getElementById(id).value;
}

function validateName(name){
  if (name.length > 3 & name.length < 25) {
    return true;
  }
  return false;
}

function validateEmail(email) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

function validatePhone(phone) {
  if (phone.length === 0 || /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone)) {
    return true;
  }
  return false;
}

function validateMessage(message) {
  if (message.length > 0) {
    return true;
  }
  return false;
}

export const validateContact = (e) => {
  e.preventDefault();

  var name = getInputVal('name');
  var email = getInputVal('email');
  var phone = getInputVal('phone');
  var message = getInputVal('message');

  if (!validateName(name)) {
    document.getElementById('name_error').classList.remove('hidden');
    console.log("Invalid contact form input from field: name");
    return false;
  } else {
    document.getElementById('name_error').classList.add('hidden');
  }

  if (!validateEmail(email)) {
    document.getElementById('email_error').classList.remove('hidden');
    console.log("Invalid contact form input from field: email");
    return false;
  } else {
    document.getElementById('email_error').classList.add('hidden');
  }

  if (!validatePhone(phone)) {
    document.getElementById('phone_error').classList.remove('hidden');
    console.log("Invalid contact form input from field: phone");
    return false;
  } else {
    document.getElementById('phone_error').classList.add('hidden');
  }

  if (!validateMessage(message)) {
    document.getElementById('message_error').classList.remove('hidden');
    console.log("Invalid contact form input from field: message");
    return false;
  } else {
    document.getElementById('message_error').classList.add('hidden');
  }
  
  return true;
};