import './resources/App.css';
import Main from "components/layouts/main"
import { recallAppSession } from "services/recallAppSession"

function App() {
  recallAppSession();
  return (
    <div  className="App">
      <Main></Main>
    </div>
  );
}

export default App;