import React from "react";
import { submitContact } from "services/submitContact";
import "./resources/page_components.css"

const Contact = () => {

    return (
        <div className="container-out">
            <div className="container-in">
                <div className="contact-form">

                    <h1>Contact Me</h1>
                    <div class="alert">Your message has been sent!</div>

                    <form id="contactForm">

                        <p><b>Name (required)</b></p>
                        <div id="name_error" class="error hidden"><b>PLEASE ENTER A VALID NAME.</b></div>
                        <input required placeholder="john doe" type="text" id="name"/>

                        <p><b>Email (required)</b></p>
                        <div id="email_error" class="error hidden"><b>PLEASE ENTER A VALID EMAIL ADDRESS.</b></div>
                        <input required placeholder="someone@example.com" type="email" id="email"/>

                        <p><b>Phone (optional)</b></p>
                        <div id="phone_error" class="error hidden"><b>PLEASE ENTER A VALID PHONE NUMBER.</b></div>
                        <input placeholder="(xxx) xxx-xxxx" type="phone" id="phone"/>

                        <p><b>Message (required)</b></p>
                        <div id="message_error" class="error hidden"><b>PLEASE ENTER A VALID MESSAGE.</b></div>
                        <textarea required placeholder="message here" id="message"></textarea>

                        <button id="submit" type="submit" onClick={submitContact}>Submit</button>
                        
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;